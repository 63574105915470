.bg {
    background-color: #eaeae8;
    position: relative;
}

body {
    font-family: Myriad;
}

.audio-controls {
    width: 100%;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
    background-color: #f6e056;
    color: #fff;
}

.player {
    background: #f6e056;
    padding: 12px;
    color: white !important;
}

.slider {
    -webkit-appearance: none;
    width: 69%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: 0.4s all ease-out;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #fff;
    cursor: pointer;
}

img {
    margin: auto 10px;
    cursor: pointer;
}

.btn-change {
    color: #3a3a3a;
    background-color: #f6e056;
    width: 130.15px;
}

.btn-change:focus {
    box-shadow: none;
}

.trademark {
    width: 100px;
    position: absolute;
    top: -62px;
    right: -36px;
}
.fs-title-content-interview{
    font-size: 1.5rem;
}

@media screen and (max-width: 790px) {
    .slider {
        width: 58%;
    }
}

@media screen and (max-width: 450px) {
    .slider {
        width: 45%;
    }

    img {
        margin: auto 3px;
    }

    .btn-change {
        width: auto;
        white-space: normal;
    }
    .fs-title-content-interview{
        font-size: 12px;
    }
}

@media screen and (max-width: 330px) {
    .slider {
        width: 39%;
    }

    img {
        margin: auto 2px;
    }

    .btn-change {
        width: auto;
        white-space: normal;
    }
    .trademark {
        width: 23%;
        position: absolute;
        top: -49px;
        right: -2px;
    }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: MyriadPro;
  src: url("./assets/font/MyriadPro-Regular.otf");
}
@font-face {
  font-family: Bahij;
  src: url("./assets/font/Bahij_TheSansArabic-Light.ttf");
}
@font-face {
  font-family: Cochin;
  src: url("./assets/font/Cochin.ttf");
}
@font-face {
  font-family: GE-Thameen-Light;
  src: url("./assets/font/GE-Thameen-Light.otf");
}
@font-face {
  font-family: myriad-pro-thin;
  src: url("./assets/font/myriad-set-pro_thin.ttf");
}
@font-face {
  font-family: Muslimah-Light;
  src: url("./assets/font/Muslimah-Light.ttf");
}
body {
  font-family: MyriadPro;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ff-bahij {
  font-family: Bahij;
}

.ff-myriad {
  font-family: MyriadPro;
}
.ff-myriad-thin {
  font-family: myriad-pro-thin;
}

.fs-title {
  font-size: 2rem;
}
.fs-title-content {
  font-size: 2.5rem;
}
.fs-view {
  font-size: 1.5rem;
}
.ff-muslimah {
  font-family: Muslimah-Light;
}
.pl2{
  padding-left:3rem ;
}
@media screen and (max-width: 420px) {
  .fs-title {
    font-size: 1.25rem;
  }
  .fs-view {
    font-size: 1rem;
  }
  .fs-title-content{
    font-size: 12px;
  }
  .pl2{
    padding-left:15px !important ;
  }
  .topview{
    margin-top: 1.5rem !important;
  }
}

.container-fix .row{
margin: 0 -15px;
}
.text-break{
  word-break: break-word;
}
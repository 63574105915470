.title-fs{
    font-size: 3rem;
}

@media only screen and (max-width: 540px) {
    .title-fs {
        font-size: 2rem !important;
    }
    .container-fix{
        max-width: 350px !important;
    }
    .sm-title-poem{
        font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 425px) {
    .container-fix{
        max-width: 320px !important;
    }
  }
  @media only screen and (max-width: 375px) {
    .container-fix{
        max-width: 300px !important;
    }
  }
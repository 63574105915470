.input-name {
    border-radius: 25px;
    height: 50px;
    width: 300px;
    border: 2px solid #f5e162;
}

.input-textarea {
    border-radius: 90px;
    height: 165px;
    width: 85%;
    padding: 1.5rem;
    border: 2px solid #f5e162;
}
.input-textarea:focus,
.input-name:focus,
.input-name:active,
.input-textarea:active {
    outline: none;
    border: 2px solid #f5e162;
    box-shadow: none;
}

.comment-submit {
    border: 2px solid #f5e162;
    background: #fff;
    border-radius: 25px;
    box-shadow: none !important;
}

.submit-btn{
    display: flex;
    margin-right: 49%;
    justify-content: center;
}

.trademark-comment {
    width: 100px;
    position: absolute;
    left: -23px;
    bottom: -50px;
}
.pdf-content{
    height: 500px !important; 
    overflow-y: scroll !important;
}
.react-pdf__Page__canvas{
    width: auto !important;
    margin: 0 auto;
    max-width: 100% !important;
}
.fs-title-view{
    font-size: 2.5rem;
}
.view-pdf{
    padding-bottom: 15rem;
}
object{
    height: 500px !important;
}
@media screen and (max-width: 450px) {

    .fs-title-view {
        font-size: 2rem !important;
    }
    
.trademark-comment {
    width: 75px;
    position: absolute;
    left: -4px;
    bottom: -45px;
}

.input-name{
width: 210px;
}
.view-pdf{
margin: 1rem !important;
padding-bottom: 11.5rem  !important;
}
.input-textarea {
    border-radius: 52px;
    height: 102px;
    width: 280px;
}
.sm-view-video{
    margin: 0px !important;
}
object{
    height: 220px !important;
}
.pdf-content+.trademark-comment{
    width: 75px;
    position: absolute;
    left: -5px;
    bottom: 39% !important;
}
}
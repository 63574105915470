.row{
    padding: 0px;
    margin: 0px;
}
.hr-line{
    border: 2px solid #F6E056;
}
.border-bt{
    border-bottom: 2px solid #F6E056;
}
.fm-control {
    width: 75%;
    border-radius: 1.25rem;
    border: 1px solid #F6E056
}

.footer-bg {
    background-color: #EAEAE8;
    direction: ltr !important;
}
.img-trademark-footer{
    width: 65%;
}

.img-media{
    width: 30px;
    margin: 6px;
}
address
.footer,.footer:hover{
    color: black;
}
@media only screen and (max-width: 990px) {
    .col-12 {
        padding: 0px;
        padding-left: 0px;
        padding-right: 0px;

    }
}

@media only screen and (max-width: 540px) {
    .fm-control {
        width: 100%;
    }
    
  }
/* @font-face {
    font-family: Myriad;
    src: url("./MyriadPro-Regular.otf");
} */
.bg {
    background-color: #eaeae8;
}
.audio-controls {
    width: 100%;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
    background-color: #f6e056;
    color: #fff;
}

.player {
    background: #f6e056;
    padding: 12px;
    color: white !important;
    width: 97% !important;
}
.rhap_container {
    background: #f6e056 !important;
}

.slider {
    -webkit-appearance: none;
    width: 69%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: 0.4s all ease-out;
}

.slider:hover {
    opacity: 1;
}
.rhap_volume-container{
    margin-top: 0.25rem;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}
.rhap_volume-controls {
    /* display: flex; */
    /* flex: 1; */
    justify-content: flex-end !important;
    align-items: center !important;
    margin-right: 7rem !important;
}
.rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 40px !important;
    /* margin-right: -3.5rem !important; */
}
.rhap_volume-button {
flex: 0 0 145px !important;
}
.slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #fff;
    cursor: pointer;
}

img {
    margin: auto 10px;
    cursor: pointer;
}

.rhap_time,
.rhap_volume-bar-area {
    display: none !important;
}
.rhap_progress-container {
    width: 450px;
    top: 28px;
    position: absolute;
    right: 18%;
}
.rhap_button-clear {
    overflow: initial !important;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 8px !important;
    height: 8px !important;
    margin-left: -2px !important;
    top: -3px !important;
    background: #fff !important;
    border-radius: 50px;
    box-shadow: rgb(134 134 134 / 50%) 0 0 5px;
}
.rhap_progress-bar {
    height: 2px !important;
}
.rhap_progress-filled{
    background-color: #fff !important;
}
.rhap_container {
    padding: 0px !important;
}
.rhap_progress-bar-show-download{
    background-color:#fff;
}
.trademark {
    width: 100px;
    position: absolute;
    top: -62px;
    right: -36px;
}
.rhap_stacked .rhap_controls-section {
    margin-top: -2px !important;
    padding-bottom: 5px !important;
}
.fs-title-audio-content{
    font-size: 1.5rem;
}
/* .rhap_additional-controls{
    position: absolute;
    right: 3.5rem;
    bottom: 1.75rem;

} */
.settings{
    width: 42%;
}
.dropdown-menu.show{
    left:0px !important
}
.download-container{
    position: absolute;
    top: 34%;
    right: 4rem;
}
.download-container-ar{
    position: absolute;
    top: 34%;
    right: 2.5rem;
}
.rhap_container.player.ar-player .rhap_progress-container{
    right: 16% !important;
}
@media screen and (max-width: 1024px) {
    .rhap_progress-container {
        width: 335px;
        right: 21%;
    }
    .rhap_volume-button {
        flex: 0 0 130px !important;
        }
}
@media screen and (max-width: 790px) {
    .slider {
        width: 58%;
    }
    .rhap_progress-container {
        width: 170px;
        right: 30%;
    }
}

@media screen and (max-width: 450px) {
    .slider {
        width: 45%;
    }

    img {
        margin: auto 3px;
    }
    .rhap_volume-controls {
        flex: none !important;
    }
    .rhap_main-controls-button {
        width: 20px !important;
    }
    .rhap_progress-container {
        width: 95px !important;
        right: 19%;
    }
    .fs-title-audio-content{
        font-size: 1rem;
    }
    .rhap_volume-controls{
        flex: auto !important;
    }
    .rhap_progress-bar{
        display:none;
    }
    .settings{
        width: 70%;
    }
    .rhap_button-clear{
        padding: 0 0.7rem !important;
    }
    .rhap_volume-button{
        margin-left: -1rem !important;
    }
    .rhap_additional-controls, .rhap_repeat-button{
        right: 3rem !important;
        display: none !important;
    }
    .dropdown-menu.show{
        left:-35px !important
    }
    .rhap_volume-button {
        flex: 0 0 100px !important;
        }
        .download-container{
            position: absolute;
            top: 34%;
            right: 3rem;
        }
}
@media screen and (max-width: 420px) {
    .rhap_progress-container {
        width: 75px !important;
    }
    .trademark {
        width: 70px;
        position: absolute;
        top: -38px;
        right: -28px;
    }
    .fs-title-audio-content{
        padding-right: 1rem !important;
    }
    .rhap_volume-controls{
        flex: auto !important;
    }
    .rhap_additional-controls{
        right: 2.25rem !important;
        display: none !important;
    }
    .download-container{
        position: absolute;
        top: 34%;
        right: 2.5rem;
    }
}
@media screen and (max-width: 330px) {
    .slider {
        width: 39%;
    }

    img {
        margin: auto 2px;
    }
    .trademark {
        width: 23%;
        position: absolute;
        top: -49px;
        right: -2px;
    }
    .rhap_progress-container {
        width: 50px !important;
        right: 19%;
    }
    .fs-title-audio-content{
        padding-right: 1rem !important;
    }
    .rhap_volume-controls{
        flex: auto !important;
    }
    .rhap_additional-controls{
        right: 2.25rem !important;
        display: none !important;
    }
    .rhap_volume-container{
        margin-left: -10px !important;
    }
    .download-container{
        position: absolute;
        top: 34%;
        right: 2.5rem;
    }
}
.rhap_button-clear.rhap_repeat-button, .rhap_additional-controls{
display: none !important;
}
.rhap_rewind-button{
    order: 2;
}
.rhap_forward-button{
    order:3
}
.rhap_play-pause-button{
    padding-left: 0.5rem !important;
}

.first-col {
    background-color: #eaeae8;
}

.second-col {
    
    display: grid;
    justify-content: center;
}
.pl-30{
    padding-left: 30px;
}
.sm-image {
    width: 100%;
}
.trademark-home {
    float: right;
    position: inherit;
    top: -50px;
    right: -60px;
    display: flex;
}
.img-trademark {
    position: absolute;
    right: -87px;
    bottom: 100px;
    width: 35%;
}

.img-border {
    border: 2px solid #f6e05669;
    padding: 10px;
}

.trademark-text {
    padding-top: 3.5rem;
}

.book-badder {
    border-bottom: 5px solid #f6e056;
}
.title {
    padding-top: 10px;
    position: absolute;
    left: 48px;
}
.title_ar {
    padding-top: 10px;
    position: absolute;
    right: 48px;
}
.img-content {
    margin-top: -3rem !important;
    padding-top: 0px !important;
}
.img-content-ar {
    margin-left: -1.5rem;
}
.img-trademark-ar {
    position: absolute;
    right: -65px;
    bottom: -17px;
    width: 35%;
}
.text-possion {
    margin-top: -2.5rem;
    padding-top: 0px;
}

@media only screen and (max-width: 1024px) {
    .text-possion {
        margin-top: -4rem;
        padding-top: 0px;
    }
    .img-trademark,.img-trademark-ar {
        position: absolute;
        right: -80px;
        bottom: 177px;
        width: 35%;
    }
}

@media only screen and (max-width: 990px) {
    .first-col {
        order: 2;
    }
    .second-col {
        order: 1;
        direction: ltr;
    }
    .sm-image {
        width: 100%;
        margin: auto;
    }
    .trademark-home {
        right: 0px;
    }
    .img-trademark,.img-trademark-ar {
        position: absolute;
        right: -15px;
        bottom: 3px;
        width: 30%;
    }
    .text-possion {
        margin-top: 0rem;
        padding-top: 0px;
    }
    .pl-30{
        padding-left: 0px;
    }
}
@media only screen and (max-width: 580px) {
    .img-content,.img-content-ar {
        margin-top: 0px !important;
        padding-top: 0.5rem !important;
        font-size: 11px !important;
        padding-left: 1rem;
    }
    .img-content-ar{
        padding-left: 2.5rem;
    }
    .sm-image{
        margin-bottom: 1rem;
    }
}

/* .nav-link:focus {
  border-bottom: 5px solid #f6e056 !important;
} */
.nav-link:focus,
.nav-link:hover {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.nav-link{
  padding: 0px !important;
  margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  font-size: 18px;
}
.navbar-nav > .active > a {
  border-bottom: 5px solid #f6e056 !important; 
}

.nav-item > a:hover {
  color: aqua;
}

.bg-color {
  background-color: #f6e056;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  direction: ltr !important;
}
.header-ff{
  font-family: Cochin;
}
.lang-ff{
  font-family: GE-Thameen-Light !important;
}

.container-top{
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.sidenav a {
  padding-left:10px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.active {
  border-bottom: 5px solid #f6e056;
  color: black !important;
}

.bbl, .bbl+.nav-link:focus{
  border-bottom: 1px solid black !important;
  padding: 8px 8px 8px 22px;
}
.bbt{
  border-top: 1px solid black;
}
.fs-2{
  font-size: 2rem;
}
.navbar-light .navbar-nav .nav-link, .nav-link:focus, .nav-link:hover {
color: black !important;
font-size: 22px !important;
}
.fs-20{
  font-size: 20px;
}
.logo-title {
  font-size: 2rem !important;
}
@media screen and (max-width: 550px){
  .logo-title {
    font-size: 17px !important;
  }
  .nav-link:hover,.nav-link:focus, .nav-link:active {
    border-bottom:none !important ;
    border-left: 5px solid #f6e056;
    padding-left: 0.5rem !important;
    color: black !important;
  }
  .fa-bar{
    font-size: 24px;
  }
  .active {
    border-bottom:none !important ;
    border-left: 5px solid #f6e056;
    padding-left: 0.5rem !important;
    color: black !important;
  }
  .container-top{
    margin-top: 2rem !important;
    margin-bottom: 0rem !important;
  }
} 

@media screen and (max-width: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  
  .logo-title {
    font-size: 17px !important;
  }
  .fa-bar {
    font-size: 24px;
  }
  .nav-link:hover,.nav-link:focus, .nav-link:active {
    border-bottom:none !important ;
    border-left: 5px solid #f6e056;
    color: black !important;
    padding-left: 0.5rem !important;
  }
  .active {
    border-bottom:none !important ;
    border-left: 5px solid #f6e056;
    padding-left: 0.5rem !important;
    color: black !important;
  }
  .sidenav a {
    font-size: 18px;
    margin: 5px 0px !important;
  }
}


.styled-select {
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 3px;
  
  overflow: hidden;
  position: relative;
}
.styled-select, .styled-select select { width: 180px;
  margin: 5px;}
select:focus { outline: none; }
.styled-select select {
  height: 34px;
  padding: 5px 0 5px 5px;
  background: transparent;
  border: none;
  
  /*hide default down arrow in webkit */
  -webkit-appearance: none; 
}

@-moz-document url-prefix(){
  .styled-select select { width: 180px;
    margin: 5px; }
}

.fa-sort-desc {
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 24px;
}

 select::-ms-expand { display: none; } /* hide default down arrow in IE10*/

 .dropdown-item.active, .dropdown-item:active{
   background-color: inherit;
 }
.styled-select.ar-select .fa-sort-desc{
  left:5px !important
}
  